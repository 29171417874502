














































import Vue from 'vue';
import axios from 'axios';
import { SmartTable, Config, State, Column, Sorting, PagingOptions } from 'rey-vue-smarttable';
import EntityType from '@/models/EntityType';
import FieldDefinitionLoader from '@/services/FieldDefinitionLoader';
import AuthenticationService from '@/services/AuthenticationService';

const LoadState = Object.freeze({ NotStarted: 0, Started: 1, Succeeded: 2, Failed: 3 });

export default Vue.extend({
  name: 'geometryColorParameters',
  components: { SmartTable },
  data() {
    return {
      AuthenticationService: AuthenticationService,
      LoadState,
      smartTableState: new State(new Sorting('GeometryColorParameterId', 'ascending')),
      smartTableConfig: new Config(
        (row) => row.GeometryColorParameterId,
        [
          new Column({
            title: this.$t('views.admin.geometryColorParameter.tableHeaders.id').toString(),
            fieldName: 'GeometryColorParameterId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.admin.geometryColorParameter.tableHeaders.colorCode').toString(),
            fieldName: 'ColorCode',
            fieldType: 'string',
            editable: () => AuthenticationService.isAuthenticated,
            validator: (newValue) => ({
              isValid: !!newValue && newValue.length <= 50,
              errorMessages: [
                this.$t('validationErrors.invalidTextLength', {
                  currentValue: newValue,
                  maxLength: 50
                }).toString()
              ]
            })
          }),
          new Column({
            title: this.$t('views.admin.geometryColorParameter.tableHeaders.geometry').toString(),
            fieldName: 'Geometry',
            fieldType: 'string',
            editable: () => AuthenticationService.isAuthenticated,
            validator: (newValue) => ({
              isValid: !!newValue && newValue.length <= 30,
              errorMessages: [
                this.$t('validationErrors.invalidTextLength', {
                  currentValue: newValue,
                  maxLength: 30
                }).toString()
              ]
            })
          }),
          new Column({
            title: this.$t(
              'views.admin.geometryColorParameter.tableHeaders.isSuggestion'
            ).toString(),
            fieldName: 'IsSuggestion',
            fieldType: 'boolean',
            defaultValue: false,
            editable: () => AuthenticationService.isAuthenticated
          })
        ],
        new Sorting('GeometryColorParameterId', 'ascending'),
        new PagingOptions(100)
      ).withEfApiActions(axios, 'GeometryColorParameters'),
      selected: undefined as unknown | undefined,
      windowPartLoadState: LoadState.NotStarted
    };
  },
  async created() {
    await FieldDefinitionLoader.loadDynamicColumnDefinitionsToSmartTableConfig(
      EntityType.GeometryColorParameter,
      this.smartTableConfig,
      true
    );
  }
});
